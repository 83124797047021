<template>
  <div>
    <h3>下载模板</h3>
    <el-table :data="data" border stripe>
      <el-table-column label="类型" prop="label" width="100"></el-table-column>
      <el-table-column label="模板">
        <template slot-scope="scope">
          <a
            class="template-link"
            :href="filePath(item)"
            :key="item"
            v-for="item in scope.row.array"
            >{{ item }}</a
          >
        </template>
      </el-table-column>
    </el-table>
    <h3>导入文件</h3>
    <el-upload
      class="upload"
      ref="upload"
      :action="url"
      :headers="token"
      :multiple="false"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handleError"
    >
      <el-button slot="trigger" type="primary" :loading="loading"
        >选取文件</el-button
      >
    </el-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      url: this.$g.APIHOST + "Upload/ImportExcel",
      token: {
        Authorization: "Bearer " + (sessionStorage["token"] || ""),
      },
      data: [
        {
          label: "类别资料",
          array: ["客户类别", "供应商类别", "物资类别"],
        },
        {
          label: "基础资料",
          array: ["客户资料", "供应商资料", "项目物资", "仓库资料", "员工资料"],
        },
        {
          label: "其他资料",
          array: ["工种资料", "费用类别"],
        },
        {
          label: "期初资料",
          array: ["客户期初", "供应商期初", "仓库期初", "项目期初"],
        },
      ],
    };
  },
  methods: {
    filePath(file) {
      return (
        "https://zxhd-upload-template.oss-cn-beijing.aliyuncs.com/" +
        file +
        ".xlsx"
      );
    },
    beforeUpload() {
      this.loading = true;
    },
    handleSuccess(res) {
      this.loading = false;
      this.$message({
        message: "上传成功",
        type: "success",
      });
      if (res && res.info && res.list && res.list.length > 0) {
        res.list.forEach((item) => {
          this.$store.commit("addBaseInfo", {
            info: res.info,
            item: item,
          });
        });
      }
    },
    handleError(err) {
      this.loading = false;
      this.$alert(err.toString().slice("Error:".length), "发生错误", {
        type: "error",
      });
    },
  },
};
</script>

<style scoped>
a.template-link {
  margin: 0 8px;
}
.upload {
  width: 360px;
}
</style>